export const cards = [
    {
      id: 1,
      img: 'descent/descent_1.jpg',
      title: 'Descent into hell or Resurrection.',
      subtitle: '1st Epistle to the Corinthians by St. Paul chapter',
      subtitle_add: 'book-15 chapter 55 verse.',
      text: '"O Death, where is your sting? O Hades, where is your victory?"',
      price: '',
      descriptions: {
        size: '40*50 sm',
        materials: 'Wooden board, cold encaustic, natural pigments, wax varnish.'
      },
      slides: [
        {
          src: 'descent/descent_1.jpg', 
        },
        {
          src: 'descent/descent_2.jpg'
        },
        {
          src: 'descent/descent_3.jpg'
        },
        {
          src: 'descent/descent_4.jpg'
        },
        {
          src: 'descent/descent_5.jpg'
        },
        {
          src: 'descent/descent_6.jpg'
        },
        {
          src: 'descent/descent_7.jpg'
        },
        {
          src: 'descent/descent_8.jpg'
        },
        {
          src: 'descent/descent_9.jpg'
        },
        {
          src: 'descent/descent_10.jpg'
        },
        {
          src: 'descent/descent_11.jpg'
        },
        {
          src: 'descent/descent_12.jpg'
        },
        {
          src: 'descent/descent_13.jpg'
        },
        {
          src: 'descent/descent_14.jpg'
        },
        {
          src: 'descent/descent_15.jpg'
        },
        {
          src: 'descent/descent_16.jpg'
        },
        {
          src: 'descent/descent_17.jpg'
        }
      ]
    },
    {
      id: 2,
      img: 'chrisma/chrisma_1.jpg',
      title: 'Chrisma',
      subtitle: 'The initial letters of the name Jesus Christ - XR.',
      subtitle_add: ' ',
      text: 'Chrisma is an image that became widespread already in the early Christian period.',
      price: '',
      descriptions: {
        size: '30*30 sm',
        materials: 'Wooden board, hot encaustic, stones from Patmos'
      },
      slides: [
        {
          src: 'chrisma/chrisma_1.jpg'
        },
        {
          src: 'chrisma/chrisma_2.jpg'
        },
        {
          src: 'chrisma/chrisma_3.jpg'
        },
        {
          src: 'chrisma/chrisma_4.jpg'
        }
      ]
    },
    {
      id: 3,
      img: 'adelaide/adelaide_1.jpg',
      title: 'Saint Adelaide',
      subtitle: 'Wooden board, cold encaustic, pigments.',
      subtitle_add: ' ',
      text: '',
      price: '',
      descriptions: {
        size: '26*55 sm',
        materials: 'Wooden board, cold encaustic, pigments.'
      },
      slides: [
        {
          src: 'adelaide/adelaide_1.jpg'
        },
        {
          src: 'adelaide/adelaide_2.jpg'
        },
        {
          src: 'adelaide/adelaide_3.jpg'
        },
        {
          src: 'adelaide/adelaide_4.jpg'
        },
        {
          src: 'adelaide/adelaide_5.jpg'
        },
        {
          src: 'adelaide/adelaide_6.jpg'
        }
      ]
    },
    {
      id: 4,
      img: 'mother_jesus/mother_jesus_1.jpg',
      title: 'Mother of God and Jesus Christ. ',
      subtitle: 'Pair of icons.',
      subtitle_add: ' ',
      text: 'Great wedding gift.',
      price: '',
      descriptions: {
        size: ' 30*40 sm',
        materials: 'Wooden boards, cold encaustic, gold.'
      },
      slides: [
        {
          src: 'mother_jesus/mother_jesus_1.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_2.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_3.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_4.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_5.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_6.jpg'
        },
        {
          src: 'mother_jesus/mother_jesus_7.jpg'
        }
      ]
    },
    {
      id: 5,
      img: 'luke_calligraphy/luke_calligraphy_1.jpg',
      title: 'Luke, Chapter 22, verses 14- 30',
      subtitle: 'On the second page there is an image of the Last Supper.',
      subtitle_add: 'The text is written in Latin.',
      text: '14 When the hour came, Jesus and his apostles reclined at the table.<br>15 And he said to them, "I have eagerly desired to eat this Passover with you before I suffer. <br> 16 For I tell you, I will not eat it again until it finds fulfillment in the kingdom of God."<br>17 After taking the cup, he gave thanks and said, "Take this and divide it among you. <br>18 For I tell you I will not drink again of the fruit of the vine until the kingdom of God comes."<br>19 And he took bread, gave thanks and broke it, and gave it to them, saying, "This is my body given for you; do this in remembrance of me."<br>20 In the same way, after the supper he took the cup, saying, "This cup is the new covenant in my blood, which is poured out for you.<br>21 But the hand of him who is going to betray me is with mine on the table.<br>22 The Son of Man will go as it has been decreed, but woe to that man who betrays him."<br>23 They began to question among themselves which of them it might be who would do this.<br>24 Also a dispute arose among them as to which of them was considered to be greatest.<br>25 Jesus said to them, "The kings of the Gentiles lord it over them; and those who exercise authority over them call themselves Benefactors.<br>26 But you are not to be like that. Instead, the greatest among you should be like the youngest, and the one who rules like the one who serves.<br>27 For who is greater, the one who is at the table or the one who serves? Is it not the one who is at the table? But I am among you as one who serves.<br>28 You are those who have stood by me in my trials.<br>29 And I confer on you a kingdom, just as my Father conferred one on me,<br>30 so that you may eat and drink at my table in my kingdom and sit on thrones, judging the twelve tribes of Israel.',
      price: '',
      descriptions: {
        size: 'Dimensions with a passe-partout are 58*79 sm.',
        materials: 'Paper, ink, pen, gum Arabic, natural pigments, gold.',
        note: 'Framed work under stack'
      },
      slides: [
        {
          src: 'luke_calligraphy/luke_calligraphy_1.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_2.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_3.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_4.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_5.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_6.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_7.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_8.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_9.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_10.jpg'
        },
        {
          src: 'luke_calligraphy/luke_calligraphy_11.jpg'
        }
      ]
    },
    {
      id: 6,
      img: 'faith_hope_love/faith_hope_love_1.jpg',
      title: 'Faith. Hope. Love.',
      subtitle: 'The inscriptions are in Latin: “Fides.Spes.Caritas.”',
      subtitle_add: '',
      text: 'These images are inspired by the eternal and beautiful Rome. Each of the figures is the personification of a well-known Christian virtue. Anthropomorphism - this technique was very common among ancient artists. The figures are in the oranta pose, which means “praying.”',
      price: '',
      descriptions: {
        size: '26*55 sm',
        materials: 'Wooden boards, hot and cold encaustic.',
        note: 'Possibility of sale individually.'
      },
      slides: [
        {
          src: 'faith_hope_love/faith_hope_love_1.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_2.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_3.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_4.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_5.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_6.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_7.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_8.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_9.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_10.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_11.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_12.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_13.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_14.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_15.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_16.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_17.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_18.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_19.jpg'
        },
        {
          src: 'faith_hope_love/faith_hope_love_20.jpg'
        }
      ]
    },
    {
      id: 7,
      img: 'triptych/triptych_1.jpg',
      title: 'Triptych "Alpha and Omega"',
      subtitle: 'The central part depicts the Ascension of the Lord.  ',
      subtitle_add: '  ',
      text: 'Below is a text from the Acts of the Apostles (Ch. 1:10-11), which describes the moment of the ascension. On the left side of the triptych there is a quote from the Gospel of John (Ch. 14:15-17), which says that the Holy Spirit will be sent to those who love Christ and His commandments. On the right side of the work is an excerpt from the Revelation of John the Theologian - the Apocalypse (Ch. 22: 11-13), which contains a formidable reminder that at the end of times everyone will receive what they deserve.',
      price: '',
      descriptions: {
        size: '35*35 sm 30*30 sm 30*30 sm', 
        materials: 'Hand-dyed purple sheets of paper, gold.'
      },
      slides: [
        {
          src: 'triptych/triptych_1.jpg'
        },
        {
          src: 'triptych/triptych_2.jpg'
        },
        {
          src: 'triptych/triptych_3.jpg'
        },
        {
          src: 'triptych/triptych_4.jpg'
        },
        {
          src: 'triptych/triptych_5.jpg'
        },
        {
          src: 'triptych/triptych_6.jpg'
        },
        {
          src: 'triptych/triptych_7.jpg'
        },
        {
          src: 'triptych/triptych_8.jpg'
        },
        {
          src: 'triptych/triptych_9.jpg'
        },
        {
          src: 'triptych/triptych_10.jpg'
        },
        {
          src: 'triptych/triptych_11.jpg'
        },
        {
          src: 'triptych/triptych_12.jpg'
        }
      ]
    },
    {
      id: 8,
      img: 'source/source_1.jpg',
      title: 'Source of Life',
      subtitle: 'Parchment, gum Arabic, pigments, gold.',
      subtitle_add: '  ',
      text: 'An image symbolizing the Creator as the source of eternal life and grace.',
      price: '',
      descriptions: {
        size: '35*46 sm', 
        materials: 'Parchment, gum Arabic, pigments, gold.'
      },
      slides: [
        {
          src: 'source/source_1.jpg'
        },
        {
          src: 'source/source_2.jpg'
        },
        {
          src: 'source/source_3.jpg'
        },
        {
          src: 'source/source_4.jpg'
        },
        {
          src: 'source/source_5.jpg'
        },
        {
          src: 'source/source_6.jpg'
        },
        {
          src: 'source/source_7.jpg'
        },
        {
          src: 'source/source_8.jpg'
        },
        {
          src: 'source/source_9.jpg'
        },
        {
          src: 'source/source_10.jpg'
        },
        {
          src: 'source/source_11.jpg'
        },
        {
          src: 'source/source_12.jpg'
        },
        {
          src: 'source/source_13.jpg'
        },
        {
          src: 'source/source_14.jpg'
        },
        {
          src: 'source/source_15.jpg'
        }
      ]
    },
    {
      id: 9,
      img: 'birds/birds_1.jpg',
      title: 'Chrisma with birds',
      subtitle: 'Oak board, mosaic, natural stones, wax glue. ',
      subtitle_add: '  ',
      text: '',
      price: '',
      descriptions: {
        size: '20*30 sm',
        materials: 'Oak board, mosaic, natural stones, wax glue.'
      },
      slides: [
        {
          src: 'birds/birds_1.jpg'
        },
        {
          src: 'birds/birds_2.jpg'
        },
        {
          src: 'birds/birds_3.jpg'
        },
        {
          src: 'birds/birds_4.jpg'
        },
        {
          src: 'birds/birds_5.jpg'
        },
        {
          src: 'birds/birds_6.jpg'
        },
        {
          src: 'birds/birds_7.jpg'
        },
        {
          src: 'birds/birds_8.jpg'
        },
        {
          src: 'birds/birds_9.jpg'
        },
        {
          src: 'birds/birds_10.jpg'
        },
        {
          src: 'birds/birds_11.jpg'
        }
      ]
    },
    {
      id: 10,
      img: 'cross_mozaic/cross_mozaic_1.jpg',
      title: 'The cross',
      subtitle: 'Oak board, mosaic, natural stones, wax glue.',
      subtitle_add: '  ',
      text: '',
      price: '',
      descriptions: {
        size: '15*30 sm',
        materials: 'Oak board, mosaic, natural stones, wax glue.'
      },
      slides: [
        {
          src: 'cross_mozaic/cross_mozaic_1.jpg'
        },
        {
          src: 'cross_mozaic/cross_mozaic_2.jpg'
        },
        {
          src: 'cross_mozaic/cross_mozaic_3.jpg'
        },
        {
          src: 'cross_mozaic/cross_mozaic_4.jpg'
        },
        {
          src: 'cross_mozaic/cross_mozaic_5.jpg'
        },
        {
          src: 'cross_mozaic/cross_mozaic_6.jpg'
        }
      ]
    },
    {
      id: 11,
      img: 'cross/cross_1.jpg',
      title: 'Cross in the middle of the wreath',
      subtitle: 'Oak board, lapis lazuli, natural stones, wax glue.',
      subtitle_add: '  ',
      text: '',
      price: '',
      descriptions: {
        size: '25*30 sm',
        materials: 'Oak board, lapis lazuli, natural stones, wax glue.'
      },
      slides: [
        {
          src: 'cross/cross_1.jpg'
        },
        {
          src: 'cross/cross_2.jpg'
        },
        {
          src: 'cross/cross_3.jpg'
        },
        {
          src: 'cross/cross_4.jpg'
        },
        {
          src: 'cross/cross_5.jpg'
        },
        {
          src: 'cross/cross_6.jpg'
        },
        {
          src: 'cross/cross_7.jpg'
        },
        {
          src: 'cross/cross_8.jpg'
        },
        {
          src: 'cross/cross_9.jpg'
        },
        {
          src: 'cross/cross_10.jpg'
        }
      ]
    },
    {
      id: 12,
      img: 'crown/crown_1.jpg',
      title: 'Crown of thorns',
      subtitle: 'Wooden board, hot encaustic, volcanic stones from Pompei',
      subtitle_add: '  ',
      text: '',
      price: '',
      descriptions: {
        size: '30*30 sm',
        materials: 'Wooden board, hot encaustic, volcanic stones from Pompei'
      },
      slides: [
        {
          src: 'crown/crown_1.jpg'
        }
      ]
    },
    {
      id: 13,
      img: 'theologian/theologian_1.jpg',
      title: 'John Theologian',
      subtitle: 'Papyrus, ink, gum Arabic, pigments.',
      subtitle_add: ' ',
      text: '',
      price: '',
      descriptions: {
        size: '42*64 sm',
        materials: 'Papyrus, ink, gum Arabic, pigments.'
      },
      slides: [
        {
          src: 'theologian/theologian_1.jpg'
        },
        {
          src: 'theologian/theologian_2.jpg'
        },
        {
          src: 'theologian/theologian_3.jpg'
        },
        {
          src: 'theologian/theologian_4.jpg'
        },
        {
          src: 'theologian/theologian_5.jpg'
        },
        {
          src: 'theologian/theologian_6.jpg'
        },
        {
          src: 'theologian/theologian_7.jpg'
        }
      ]
    },
    {
      id: 14,
      img: 'angel/angel_1.jpg',
      title: 'The angel who folds the sky.',
      subtitle: 'Papyrus, ink, gum Arabic, pigments.',
      subtitle_add: ' ',
      text: '',
      price: '',
      descriptions: {
        size: '42*64 sm',
        materials: 'Papyrus, ink, gum Arabic, pigments.'
      },
      slides: [
        {
          src: 'angel/angel_1.jpg'
        },
        {
          src: 'angel/angel_2.jpg'
        },
        {
          src: 'angel/angel_3.jpg'
        },
        {
          src: 'angel/angel_4.jpg'
        },
        {
          src: 'angel/angel_5.jpg'
        },
        {
          src: 'angel/angel_6.jpg'
        },
        {
          src: 'angel/angel_7.jpg'
        },
        {
          src: 'angel/angel_8.jpg'
        }
      ]
    },
    {
      id: 15,
      img: 'mark/mark_1.jpg',
      title: 'Apostle Mark',
      subtitle: 'Handmade',
      subtitle_add: '',
      text: '',
      price: '',
      descriptions: {
        size: '31*44 sm',
        materials: 'Paper, gum arabic, pigments, gold.'
      },
      slides: [
        {
          src: 'mark/mark_1.jpg'
        },
        {
          src: 'mark/mark_2.jpg'
        },
        {
          src: 'mark/mark_3.jpg'
        },
        {
          src: 'mark/mark_4.jpg'
        },
        {
          src: 'mark/mark_5.jpg'
        },
        {
          src: 'mark/mark_6.jpg'
        },
        {
          src: 'mark/mark_7.jpg'
        },
        {
          src: 'mark/mark_8.jpg'
        },
        {
          src: 'mark/mark_9.jpg'
        },
        {
          src: 'mark/mark_10.jpg'
        },
        {
          src: 'mark/mark_11.jpg'
        },
        {
          src: 'mark/mark_12.jpg'
        },
        {
          src: 'mark/mark_13.jpg'
        },
        {
          src: 'mark/mark_14.jpg'
        },
        {
          src: 'mark/mark_15.jpg'
        }
      ]
    },
    {
      id: 16,
      img: 'deum/deum_1.jpg',
      title: 'Te Deum laudamus',
      subtitle: 'Early Christian hymn.',
      subtitle_add: '',
      text: '',
      price: '',
      descriptions: {
        size: '56,5*35.5 sm',
        materials: 'Paper, ink, gum Arabic, pigments, gold.'
      },
      slides: [
        {
          src: 'deum/deum_1.jpg'
        },
        {
          src: 'deum/deum_2.jpg'
        },
        {
          src: 'deum/deum_3.jpg'
        },
        {
          src: 'deum/deum_4.jpg'
        },
        {
          src: 'deum/deum_5.jpg'
        },
        {
          src: 'deum/deum_6.jpg'
        },
        {
          src: 'deum/deum_7.jpg'
        },
        {
          src: 'deum/deum_8.jpg'
        },
        {
          src: 'deum/deum_9.jpg'
        },
        {
          src: 'deum/deum_10.jpg'
        },
        {
          src: 'deum/deum_11.jpg'
        },
        {
          src: 'deum/deum_12.jpg'
        }
      ]
    },
    {
      id: 17,
      img: 'country/country_1.jpg',
      title: 'Country',
      subtitle: 'Ukrainian ornaments from different regions.',
      subtitle_add: '',
      text: 'Handmade',
      price: '',
      descriptions: {
        size: '31*44 sm',
        materials: 'Paper, gum arabic, pigments, gold.'
      },
      slides: [
        {
          src: 'country/country_1.jpg'
        },
        {
          src: 'country/country_2.jpg'
        },
        {
          src: 'country/country_3.jpg'
        },
        {
          src: 'country/country_4.jpg'
        }
      ]
    },
    {
      id: 18,
      img: 'jesus_mother_john/jesus_mother_john_1.jpg',
      title: 'Jesus Christ on the cross. Mother of God. John Theologian',
      subtitle: 'On all three images the text is written in Ukrainian.',
      subtitle_add: '',
      text: ' These are texts from the Good Friday service when Jesus Christ was crucified.',
      price: '',
      descriptions: {
        size: '29.5*42 sm',
        materials: 'Pink paper, ink, gold.'
      },
      slides: [
        {
          src: 'jesus_mother_john/jesus_mother_john_1.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_2.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_3.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_4.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_5.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_6.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_7.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_8.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_9.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_10.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_11.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_12.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_13.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_14.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_15.jpg'
        },
        {
          src: 'jesus_mother_john/jesus_mother_john_16.jpg'
        }
      ]
    }
];