<template>
  <div display="d-flex">
    <v-col cols="12"
            md="6">
        <h1>{{ telegramHeader }}</h1>
        <v-btn
          append-icon="mdi-open-in-new" 
          color="#3fafb3"
          variant="tonal"
          :href="telegramLink" 
          target="_blank">
          {{ telegramHeader }}
      </v-btn>
    </v-col>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  telegramHeader: String,
  telegramUsername: String
}); 

const telegramLink = `https://t.me/${props.telegramUsername}`;
</script>