import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import WebFont from 'webfontloader';
import store from './store';

// Load Anta font using webfontloader
WebFont.load({
  google: {
    families: ['Montserrat:regular,bold'],
  },
  active: () => {
    // After the fonts are loaded, create and mount the app
    createApp(App)
      .use(vuetify)
      .use(router)
      .use(store)
      .mount('#app')
  },
});
