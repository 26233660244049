<template>
    <div>
        <div class="d-flex flex-column">
            <v-col cols="12"
                    md="6">
                <h1>{{ ytHeader }}</h1>
                <v-btn
                    :href="ytLink"
                    append-icon="mdi-open-in-new" 
                    color="#3fafb3"
                    variant="tonal">
                    {{ ytHeader }}
                </v-btn>
            </v-col>
            <v-col cols="12"
                    md="6">
            <YoutubeVideoComponent :youtube-video="ytVideo"/>
            </v-col>
        </div>
    </div>
</template>

<script setup>
import YoutubeVideoComponent from '@/_shared/YoutubeVideoComponent.vue';
import { defineProps } from 'vue';

defineProps({
    ytVideo: String,
    ytHeader: String,
    ytLink: String
});

</script>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VideoComponent',
  components: {
    YoutubeVideoComponent
  },
});
</script>