<template>
    <v-form
        ref="form"
        class="pa-4 pt-6"
        fast-fail 
        @submit.prevent>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            color="deep-purple"
            label="Your email"
            type="email"
            variant="filled"
        ></v-text-field>
        <v-text-field
            v-model="theme"
            :rules="textRules"
            color="deep-purple"
            label="Theme"
            variant="filled"
        ></v-text-field>
        <v-textarea
            v-model="message"
            :rules="textRules"
            color="deep-purple"
            label="Your message"
            rows="3"
            variant="filled"
            auto-grow
        ></v-textarea>

        <div class="g-recaptcha" :data-sitekey="recaptchaSiteKey"></div>

        <v-btn class="mt-2" 
                type="submit" 
                block
                :disabled="!isValid"
                @click="submitForm()">
            Submit
        </v-btn>
    </v-form>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const theme = ref('');
    const message = ref('');
    const email = ref('');

    const emailRules = [
      (value) => !!value.match(/@/) || 'Please enter a valid email', 
    ];

    const textRules = [
      (value) => {
        if (value?.length > 3) return true;
        return 'Email must be at least 3 characters.';
      },
    ];

    const recaptchaSiteKey = '6Leuf9cpAAAAAHVwX257vqm_vL_gXzPSauuxa1nC'; // Replace with your reCAPTCHA site key

    const iconsEmail = computed(() => store.getters.getEmailLink);

    const isValid = computed(() => !!iconsEmail.value && !!email.value && !!message.value && !!theme.value);

    const submitForm = () => {
      // Verify reCAPTCHA response
      const response = window.grecaptcha.getResponse();
      if (!response) {
        // Handle case when reCAPTCHA is not verified
        return;
      }
      
      if (isValid.value) {
        store.dispatch('submitFormData', {
          iconsEmail: iconsEmail.value,
          email: email.value,
          theme: theme.value,
          message: message.value, 
          recaptchaResponse: response
        });

        // Reset form values
        email.value = '';
        theme.value = '';
        message.value = '';
      }
    };

    return {
      theme,
      message,
      email,
      emailRules,
      textRules,
      recaptchaSiteKey,
      isValid,
      submitForm
    };
  }
};
</script>

