<template>
  <NavBar />
</template>

<script>
  import { defineComponent } from 'vue';
  import NavBar from '../components/NavBar'

  export default defineComponent({
    name: 'MainViewHeader',
    components: {
      NavBar
    }
  });
</script>
