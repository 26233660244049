<template>
  <v-carousel
    :show-arrows="showArrow"
    hide-delimiter-background
    :hide-delimiters="hideDelimiter"
    :height="maxImageHeight"
  >
  <v-carousel-item v-for="(slide, i) in slides" :key="i"
                :contain="true">
    <div class="carousel-item-container"
            @dblclick="toggleZoom"
            @click.self="resetZoom"
            @mousedown="startPan"
            @mouseup="endPan"
            @mouseleave="endPan"
            @mousemove="panImage">
        <img :src="require(`@/assets/${slide.src}`)" 
              class="carousel-item-image" 
              :style="imageStyle"
              @wheel.prevent="handleMouseWheel"
              @load="calculateImageHeight($event.target)" />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script setup>
  import { ref, watch, computed, defineProps, onMounted, defineEmits  } from 'vue';

 const props = defineProps({
    slides: Array,
    showArrow: Boolean,
    hideDelimiter: Boolean,
  });

  const slides = computed(() => props.slides);
  const showArrow = computed(() => props.showArrow);
  const hideDelimiter = computed(() => props.hideDelimiter);

  const emit = defineEmits(['imageHeight']);

  const isMobile = ref(false);
  const maxImageHeight = ref(500);

  const zoomLevels = [1.0, 1.5, 2.0]; 
  const zoomIndex = ref(0); 
  const panning = ref(false);
  const panStartX = ref(0);
  const panStartY = ref(0);
  const panOffsetX = ref(0);
  const panOffsetY = ref(0);

  const checkIfMobile = () => {
    const screenWidth = window.innerWidth;
    isMobile.value = screenWidth <=768;
  }

  const handleMouseWheel = () => {
    const delta = event.deltaY > 0 ? -0.1 : 0.1;
    zoomIndex.value = Math.max(0, Math.min(zoomIndex.value + delta, zoomLevels.length - 1));
  };

  const toggleZoom = () => {
    zoomIndex.value = (zoomIndex.value + 1) % zoomLevels.length; 
  };

  const resetZoom = (event) => {
    if (!event.target.classList.contains('carousel-item-image')) {
      zoomIndex.value = 0;
    }
  };

  const startPan = (event) => {
    if (zoomLevels[zoomIndex.value] > 1) {
      panning.value = true;
      panStartX.value = event.clientX;
      panStartY.value = event.clientY;
    }
  };

  const endPan = () => {
    panning.value = false;
  };

  const panImage = (event) => {
    if (panning.value) {
      const dx = event.clientX - panStartX.value;
      const dy = event.clientY - panStartY.value;
      panOffsetX.value += dx;
      panOffsetY.value += dy;
      panStartX.value = event.clientX;
      panStartY.value = event.clientY;
    }
  };

  const imageStyle = ref({
    transform: `scale(${zoomLevels[zoomIndex.value]}) translate(${panOffsetX.value}px, ${panOffsetY.value}px)`,
    transition: 'transform 0.2s ease-in-out',
  });

  const checkMaxImageHeight = () => {
    if(isMobile.value){
      maxImageHeight.value = 670
    } else {
      maxImageHeight.value = window.innerHeight;
    }
  }

  const calculateImageHeight = (img) => {
    const height = img.offsetHeight; 
    emit('imageHeight', height); 
  };

  watch([zoomIndex, panOffsetX, panOffsetY], () => {
    if (zoomIndex.value === 0) {
      panOffsetX.value = 0;
      panOffsetY.value = 0;
    }
    imageStyle.value = {
      transform: `scale(${zoomLevels[zoomIndex.value]}) translate(${panOffsetX.value}px, ${panOffsetY.value}px)`,
      transition: 'transform 0.2s ease-in-out',
    };
  });

  onMounted(() => {
    checkIfMobile();
    checkMaxImageHeight();
  });

  </script>

<style scoped>
::v-deep button{
  color: white!important;
    @media (min-width: 780px) {
      color: black!important;
    }
}

::v-deep .v-responsive__content{
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .v-window__controls{
  position: absolute;
  left: 0;
  width: 100%;

  @media (min-width: 780px) {
    /* position: fixed;
    left: -20rem;
    width: 91vw; */
  }
}

/* .carousel-item-container {
  overflow: auto;
}

.carousel-item-image {
  width: -webkit-fill-available;
  width: -moz-available;
  height: auto;
} */

.carousel-item-container {
  overflow: hidden;
  max-height: 100vh; /* Set maximum height to screen height */
}

.carousel-item-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>