import { createStore } from 'vuex';

const store = createStore({
  state: {
    sliderOpen: false,
    currentCardId: null,
    overlayOpen: false,
    formOpen: false,
    emailLink: null
  },
  mutations: {
    SET_SLIDER_OPEN(state, isOpen) {
      state.sliderOpen = isOpen;
    },
    SET_CURRENT_CARD_ID(state, cardId) {
      state.currentCardId = cardId;
    },
    SET_OVERLAY_OPEN(state, isOpen){
      state.overlayOpen = isOpen;
    },
    SET_EMAIL_FORM_OPEN(state, isOpen){
      state.formOpen = isOpen
    },
    SET_EMAIL_LINK(state, link){
      state.emailLink = link
    }
  },
  actions: {
    submitFormData({ commit }, formData) {
      console.log('Form data submitted:', formData);
      commit('SET_EMAIL_FORM_OPEN', false); 
      return Promise.resolve(); 
    },
  },
  getters: {
    isSliderOpen(state) {
      return state.sliderOpen;
    },
    getCurrentCardId(state) {
      return state.currentCardId;
    },
    isOverlayOpen(state){
      return state.overlayOpen
    },
    isFormOpen(state){
      return state.formOpen
    },
    getEmailLink(state){
      return state.emailLink
    }
  }
});

export default store;
