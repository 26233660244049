<template>
  <v-parallax
    src="@/assets/paralax/paralax.jpg"
  >
    <div class="d-flex flex-column-reverse flex-md-row justify-md-end align-md-center pt-6 pb-6">
      <v-col cols="12"
            md="4">
        <h3 class="text-right d-md-none custom-color">
          "My works, discoveries, receipts - my days - everyday life of one icon painter."
        </h3>
        <h1 class="text-right d-none d-md-block custom-color">
          "My works, discoveries, receipts - my days - everyday life of one icon painter."
        </h1>
      </v-col>
      <v-col cols="12"
            md="6">
      <CarouselComponent 
                        :slides="slides"
                        :show-arrow="false"/>
      </v-col>
    </div>
  </v-parallax>
</template>

<script setup>
  import CarouselComponent from '@/_shared/CarouselComponent.vue';
  
  import { slides } from '@/data/carouselSlides';
</script>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'HomeView',
    components: {
      CarouselComponent
    }
  });
</script>

<style scoped>
.custom-color{
  color:#f0fffb;
  font-style: italic;
}
</style>

