export const slides = [
    {
      src: 'hope_love/hope_love_1.jpg',
    },
    {
      src: 'hope_love/hope_love_2.jpg',
    },
    {
      src: 'hope_love/hope_love_3.jpg',
    }
];