export const photoTextures = [
{
  id: 1,
  photoTexture: 'room/room_merged.png',
  photoTextureMobile: 'room/room_1.jpg',
  text: 'Paintings of the monastic church in the Monastery of the Assumption of the Blessed Virgin Mary in the Pidna region (Central Macedonia, Greece). The convent is located in a hilly area, 70 kilometers from the famous Mount Olympus. Among the forest, on the site of an ancient monastery, the monastery was built today. Services are held in the main church (katholikon) on Sundays and holidays. In a small church, nuns perform their prayers every day. Now the work is being done in the vestibule (where all the paintings are done on a red background), in the main space of the church and in the altar the paintings will be on a blue background. Now some activists are reviving the technology of creating wax-based paints (this is called cold and hot encaustic). For me, these technologies are also extremely interesting and important, because these technologies were used by artists of ancient times. The Greeks painted their wooden ships and marble statues with paints made from natural beeswax. In the famous Pompeii near Naples, all the perfectly preserved wall paintings are painted using the cold encaustic technique. These are incredibly durable, vibrant and have great potential paints. Therefore, as a basis for my paints (both in creating icons and in wall painting) I use natural beeswax. This ensures longevity and the beautiful natural colors of my paintings.',
  slides: [
        {
          src: 'room/room_1.jpg'
        },
        {
          src: 'room/room_2.jpg'
        },
        {
          src: 'room/room_3.jpg'
        },
        {
          src: 'room/room_4.jpg'
        },
        {
          src: 'room/room_5.jpg'
        },
        {
          src: 'room/room_6.jpg'
        },
        {
          src: 'room/room_7.jpg'
        },
        {
          src: 'room/room_8.jpg'
        },
        {
          src: 'room/room_9.jpg'
        },
        {
          src: 'room/room_10.jpg'
        },
        {
          src: 'room/room_11.jpg'
        },
        {
          src: 'room/room_12.jpg'
        },
        {
          src: 'room/room_14.jpg'
        },
        {
          src: 'room/room_15.jpg'
        },
        {
          src: 'room/room_16.jpg'
        },
        {
          src: 'room/room_17.jpg'
        },
        {
          src: 'room/room_18.jpg'
        },
        {
          src: 'room/room_19.jpg'
        },
        {
          src: 'room/room_20.jpg'
        },
        {
          src: 'room/room_21.jpg'
        },
        {
          src: 'room/room_22.jpg'
        },
        {
          src: 'room/room_23.jpg'
        },
        {
          src: 'room/room_25.jpg'
        },
        {
          src: 'room/room_26.jpg'
        },
        {
          src: 'room/room_27.jpg'
        },
        {
          src: 'room/room_28.jpg'
        },
        {
          src: 'room/room_29.jpg'
        },
        {
          src: 'room/room_10.jpg'
        }
  ],
  add_info: [
    {
      text: 'Magnificent Mount Olympus (view from the village of Nea Trapezounta) and views of the monastery',
      photos: [
        {
          src: 'photo_church/photo_church_1.jpg'
        }, 
        {
          src: 'photo_church/photo_church_2.jpg'
        },
        {
          src: 'photo_church/photo_church_3.jpg'
        },
        {
          src: 'photo_church/photo_church_4.jpg'
        },
        {
          src: 'photo_church/photo_church_5.jpg'
        },
        {
          src: 'photo_church/photo_church_6.jpg'
        },
        {
          src: 'photo_church/photo_church_7.jpg'
        },
        {
          src: 'photo_church/photo_church_8.jpg'
        }
      ]
    }
  ]
}
]