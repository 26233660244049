<template>
  <div id="container"></div>
</template>

<script setup>
import * as THREE from 'three';
import { texture, equirectUV } from 'three/nodes';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import WebGPU from 'three/addons/capabilities/WebGPU.js';
import WebGL from 'three/addons/capabilities/WebGL.js';
import WebGPURenderer from 'three/addons/renderers/webgpu/WebGPURenderer.js';
import { onMounted, onUnmounted, ref } from 'vue';

import { defineProps } from 'vue';

const props = defineProps({
  photoTexture: String,
});

const photoTexture = ref(props.photoTexture);

let camera, scene, renderer;
let controls;

onMounted(() => {
  init();
});

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize);
});

const isMobile = ref(window.innerWidth <= 768);

window.addEventListener('resize', () => {
  isMobile.value = window.innerWidth <= 768;
});

const calc = (width) => {
  return width; 
}

const calcWidth = () => {
  return isMobile.value ? calc(window.innerWidth) : 1200;
}

const calcHeight = () => {
  return isMobile.value ? 450 : 500;
}

function init() {
  if (WebGPU.isAvailable() === false && WebGL.isWebGL2Available() === false) {
    document.body.appendChild(WebGPU.getErrorMessage());
    throw new Error('No WebGPU or WebGL2 support');
  }

  const container = document.getElementById('container');
  camera = new THREE.PerspectiveCamera(100, calcWidth() / calcHeight() - 1.7, 0.4, 30);
  camera.position.set(0, 0, 1);

  const equirectTexture = new THREE.TextureLoader().load(`assets/${photoTexture.value}`);

  scene = new THREE.Scene();
  scene.backgroundNode = texture(equirectTexture, equirectUV(), 0);

  renderer = new WebGPURenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(calcWidth(), calcHeight());
  renderer.setAnimationLoop(render);
  container.appendChild(renderer.domElement);

  controls = new OrbitControls(camera, renderer.domElement);
  controls.autoRotate = true;
  controls.rotateSpeed = -0.125; 
  controls.autoRotateSpeed = 1.0;

  window.addEventListener('resize', onWindowResize);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function render() {
  controls.update();
  renderer.render(scene, camera);
}
</script>

<style>
#container {
  width:100vw;
}
</style>
