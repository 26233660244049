<template>
  <v-overlay
        v-model="isOverlayOpen"
        class="align-center justify-center custom-position"
        contained
      >
      <div class="custom-overlay"
          :class="isMobile ? 'custom-overlay-width-mobile' : 'custom-overlay-width-desktop'"
          :style="{ width: dynamicWidth }">
        <v-btn 
          class="close-btn"
          icon 
          @click.stop="closeOverlay(false)">
          <v-icon>mdi-close-outline</v-icon>
        </v-btn>
        <CarouselComponent 
                      :slides="filteredCardSlides"
                      :show-arrow="isMobile ? false : 'hover'"
                      :hide-delimiter="isMobile ? false : true"
                      @imageHeight="onImageHeight" />
        </div>
  </v-overlay>
</template>

<script setup>
  import CarouselComponent from '@/_shared/CarouselComponent.vue';

  import { useStore } from 'vuex';

  import { ref, computed, onMounted, defineProps } from 'vue';

  const props = defineProps({
    cards: Array,
  });

  const cards = ref(props.cards);

  const isMobile = ref(false);

  const dynamicWidth = ref('40vw'); 
  const screenHeight = window.innerHeight - 300;

  const store = useStore();

  const checkIfMobile = () => {
    const screenWidth = window.innerWidth;
    isMobile.value = screenWidth <=768;
  }

  const currentCardId = computed(() => {
    return store.getters.getCurrentCardId;
  })

  const isOverlayOpen = computed(() => {
    return store.getters.isOverlayOpen;
  })

  const closeOverlay = (close) => {
    store.commit('SET_OVERLAY_OPEN', close);
};

  const filteredCards = computed(() => {
    return cards.value.filter(card => card.id === currentCardId.value);
  });

  const filteredCardSlides = computed(() => {
    if (filteredCards.value.length > 0) {
      return filteredCards.value[0].slides;
    }
    return {};
  });
  
  const onImageHeight = (height) => {
    if(isMobile.value){
      dynamicWidth.value = '100vw';
    } else {
        if (height > screenHeight && height > 650) {
          dynamicWidth.value = '30vw'; 
        } else {
          dynamicWidth.value = `${height * 2}px`;
        }
    }
  };
  
  onMounted(() => {
    checkIfMobile();
  })
</script>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    components: {
      CarouselComponent
    },
  });
</script>

<style scoped>
.custom-overlay{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.custom-overlay-width-mobile{
  width: 100vw;
  height: 100vh;
}

.custom-overlay-width-desktop{
  width: 40vw;
  height: 100vh;
}

::v-deep .v-btn--elevated {
    margin-bottom: -3rem;
    z-index: 2000;
}

.custom-position{
  position: fixed;
}

::v-deep .v-overlay__scrim{
  opacity: 1!important;
}

.close-btn{
  position:absolute;
  top: 0;
  right: 0;
 
  @media (min-width: 780px) {
      right: -8rem;
  }
}

</style>