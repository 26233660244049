<template>
    <div>
        <iframe
            :width="isMobile ? '100%' : videoWidth"
            :height="videoHeight"
            :src="youtubeVideo"
            frameborder="0"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

defineProps({
    youtubeVideo: String,
});

const videoWidth = '560px';
const videoHeight = '315px';

const screenWidth = window.innerWidth;
const isMobile = computed(() => screenWidth <= 768);

</script>