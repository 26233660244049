<template>
    <div>
        <div class="d-flex flex-column">
            <v-col cols="12"
                    md="6">
                <h1>{{ emailHeader }}</h1>
                <div class="d-flex justify-space-between align-center">
                  <v-btn
                    append-icon="mdi-open-in-new" 
                    color="#3fafb3"
                    variant="tonal"
                    @click.prevent="openForm(emailLink)">
                    {{ emailHeader }}
                  </v-btn>
                  <v-btn v-if="isFormOpen"
                      class="close-btn"
                      icon 
                      @click.stop="closeForm()">
                      <v-icon>mdi-close-outline</v-icon>
                  </v-btn>
                </div>
            </v-col>
            <v-col cols="12"
                    md="6"
                    v-if="isFormOpen">
                    <EmailFormComponent />
            </v-col>
        </div>
    </div>
</template>

<script setup>
import EmailFormComponent from '@/_shared/EmailFormComponent.vue';
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex'

defineProps({
    emailHeader: String,
    emailLink: String
});

const store = useStore()

const openForm = (link) => {
  store.commit('SET_EMAIL_FORM_OPEN', true);
  store.commit('SET_EMAIL_LINK', link);
};

const isFormOpen = computed(() => {
  return store.getters.isFormOpen
})

const closeForm = () => {
    store.commit('SET_EMAIL_FORM_OPEN', false);
};

</script>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmailComponent',
  components: {
    EmailFormComponent
  },
});
</script>