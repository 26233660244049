export const socialMedias = [
    {
      icon: 'mdi-facebook',
      link: 'https://www.facebook.com/profile.php?id=100017179511861'
    },
    {
      icon: 'mdi-youtube',
      link: 'https://www.youtube.com/@budniiconopistsya'
    }
  ]

export const contactInfo = [
    {
        yt_video: 'https://www.youtube.com/embed/5M32sf3ttLI',
        yt_header: 'YOUTUBE',
        yt_link: 'https://www.youtube.com/@budniiconopistsya',
        email_header: 'EMAIL',
        email_link: 'ksu4violin@gmail.com',
        facebook_header: 'FACEBOOK',
        facebook_link: 'https://www.facebook.com/profile.php?id=100017179511861',
        telegram_header: 'TELEGRAM',
        telegram_username: '@ksenon_icons'
    }
]