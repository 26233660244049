<template>
    <div>
        <template v-for="contact in contactInfo"
                        :key="contact.id">
            <VideoComponent :yt-video="contact.yt_video"
                            :yt-header="contact.yt_header"
                            :yt-link="contact.yt_link"/>
            <EmailComponent  class="d-none"
                            :email-header="contact.email_header"
                            :email-link="contact.email_link"/>
            <TelegramComponent  :telegram-header="contact.telegram_header"
                                :telegram-username="contact.telegram_username"/>
            <FacebookComponent  :facebook-header="contact.facebook_header"
                                :facebook-link="contact.facebook_link"/>
        </template>
    </div>
  </template>
  
  <script setup>
    import VideoComponent from '@/components/VideoComponent.vue';
    import EmailComponent from '@/components/EmailComponent.vue'; 
    import TelegramComponent from '@/_shared/TelegramComponent.vue'; 
    import FacebookComponent from '@/_shared/FacebookComponent.vue'; 
  
    import { contactInfo } from '@/data/contactInfo';
 </script>
  
  <script>
    import { defineComponent } from 'vue';
  
    export default defineComponent({
      name: 'ContactView',
      components: {
        VideoComponent,
        EmailComponent,
        TelegramComponent,
        FacebookComponent
      },
    });
  </script>