<template>
  <FooterComponent :social-medias="socialMedias"/>
</template>

<script setup>
    import { socialMedias } from '@/data/contactInfo';
</script>

<script>
  import { defineComponent } from 'vue';
  import FooterComponent from '../components/FooterComponent';

  export default defineComponent({
    name: 'MainViewFooter',
    components: {
      FooterComponent
    }
  });
</script>





