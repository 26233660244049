<template>
  <div class="custom-m-w">
    <CardComponent :cards="cards"/>
    <div v-if="isSliderOpen">
      <CarouselOverlayComponent 
                                :cards="cards"/>
    </div>
  </div>
</template>

<script setup>
  import CardComponent from '@/_shared/CardComponent.vue';
  import CarouselOverlayComponent from '@/_shared/CarouselOverlayComponent.vue';

  import { cards } from '@/data/cardInfo';

  import { useStore } from 'vuex';

  import { computed } from 'vue';

  const store = useStore();

  const isSliderOpen = computed(() => {
    return store.getters.isSliderOpen;
  });

</script>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconView',
    components: {
      CardComponent,
      CarouselOverlayComponent
    },
  });
</script>

<style scoped>
.custom-m-w{
  @media (min-width: 780px){
    margin: 1rem;
    width:100%
  }
}
</style>